@font-face {
	font-family: 'Gardenia';
	src: local('Gardenia'), url(./fonts/Gardenia.ttf) format('truetype');
}

@font-face {
	font-family: 'Bromello';
	src: local('Bromello'), url(./fonts/Bromello.ttf) format('truetype');
}

@font-face {
	font-family: 'Cabana';
	src: local('Cabana'), url(./fonts/Cabana-Regular.otf) format('opentype');
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
